// JQuery import
import $ from 'jquery';

// Slick slider import
import 'slick-carousel';

// Arrows img import
const arrowLeft = '<i class="bi bi-chevron-left"></i>';
const arrowRight = '<i class="bi bi-chevron-right"></i>';

// Slick Slider
$('.slider-clients').slick({
    autoplay: false,
    autoplaySpeed: 4500,
    infinite: true,
    speed: 200,
    slidesToShow: 2,
    slidesToScroll: 1,
    cssEase: 'ease-out',
    prevArrow:
        '<button class="prev-slide bg-body rounded-circle text-primary border-0 fs-2 fw-bolder">' +
        arrowLeft +
        '</button>',
    nextArrow:
        '<button class="next-slide bg-body rounded-circle text-primary border-0 fs-2 fw-bolder">' +
        arrowRight +
        '</button>',
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
            },
        },
    ],
});
$('.slider-employees').slick({
    autoplay: false,
    autoplaySpeed: 4500,
    infinite: true,
    speed: 200,
    slidesToShow: 2,
    slidesToScroll: 1,
    cssEase: 'ease-out',
    prevArrow:
        '<button class="prev-slide rounded-circle text-light bg-transparent fs-2 fw-bolder">' + arrowLeft + '</button>',
    nextArrow:
        '<button class="next-slide rounded-circle text-light bg-transparent fs-2 fw-bolder">' +
        arrowRight +
        '</button>',
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
            },
        },
    ],
});
